import axios, { AxiosResponse } from "axios"

import { IAdsListPayload } from "../../../types/ads"
import { axiosConfigAuth } from "../middleware"
import { API_ADS_DATA, API_ADS_GET_FILTER_LISTS, API_FAV_ADD, API_FAV_DELETE, API_FAV_LIST } from "../urls"

export const adsApi = {
    fetchData: async (data: IAdsListPayload): Promise<AxiosResponse> => {
        return axios.post(API_ADS_DATA, data, axiosConfigAuth())
    },

    fetchLists: async (): Promise<AxiosResponse> => {
        return axios.get(API_ADS_GET_FILTER_LISTS, axiosConfigAuth())
    },

    fetchFavoritesData: async (data: IAdsListPayload): Promise<AxiosResponse> => {
        return axios.post(API_FAV_LIST, data, axiosConfigAuth())
    },

    addFavoritesAd: async (id: string | number): Promise<AxiosResponse> => {
        return axios.get(`${API_FAV_ADD}/${id}`, axiosConfigAuth())
    },

    deleteFavoritesAd: async (id: string | number): Promise<AxiosResponse> => {
        return axios.get(`${API_FAV_DELETE}/${id}`, axiosConfigAuth())
    },
}
