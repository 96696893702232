import React, { FC, useEffect } from "react"

import { Routes, Route, Navigate } from "react-router-dom"

import { adminRole } from "./common/constants/roles"
import { routeNames } from "./common/constants/routes"
import { useActions } from "./common/hooks/useActions"
import { useAllowedRoles } from "./common/hooks/useAllowedRoles"
import { useTypedSelector } from "./common/hooks/useTypedSelector"
import Access from "./pages/access"
import Login from "./pages/auth/login"
import ErrorPage from "./pages/error"
import Favorites from "./pages/favorites"
import Home from "./pages/home/Home"
import NotFound from "./pages/not-found"
import BlackList from "./pages/settings/black-list/BlackList"
import CallSettings from "./pages/settings/call-settings"
import Staff from "./pages/staff"

const AppRouter: FC = () => {
    const isAdmin = useAllowedRoles([ adminRole ])

    const { isAuth } = useTypedSelector((state) => state.auth)
    const { currentUserGetData, currentUserGetLocations, currentUserGetStatus, currentUserClearData, setAppLoading } = useActions()

    useEffect(() => {
        if (isAuth) {
            setAppLoading(true)

            setTimeout(() => {
                currentUserGetData(
                    () => {
                        currentUserGetStatus(
                            () => {
                                currentUserGetLocations()
                            },
                            () => {
                                setAppLoading(false)
                            }
                        )
                    },
                    () => {
                        setAppLoading(false)
                    }
                )
            }, 500)
        } else {
            currentUserClearData()
        }
    }, [ isAuth ])

    return (
       <Routes>
           {
               isAuth ?
                   <>
                       <Route
                           path={routeNames.home}
                           element={<Home />}
                       />
                       <Route
                           path={routeNames.favorites}
                           element={<Favorites />}
                       />
                       {
                           isAdmin && (
                               <>
                                    <Route
                                        path={routeNames.staff}
                                        element={<Staff />}
                                    />
                                    {/*<Route*/}
                                    {/*    path={routeNames.access}*/}
                                    {/*    element={<Access />}*/}
                                    {/*/>*/}
                                    <Route
                                        path={routeNames.settings_call}
                                        element={<CallSettings />}
                                    />
                                    <Route
                                        path={routeNames.settings_bl}
                                        element={<BlackList />}
                                    />
                               </>
                           )
                       }

                       <Route
                           path={routeNames.notFound}
                           element={<NotFound />}
                       />
                       <Route
                           path={routeNames.error}
                           element={<ErrorPage />}
                       />

                       <Route
                           path="/"
                           element={<Navigate to={routeNames.home} replace />}
                       />
                       <Route
                           path="*"
                           element={<Navigate to={routeNames.notFound} replace />}
                       />
                   </>
                   :
                   <>
                       <Route
                           path={routeNames.login}
                           element={<Login />}
                       />

                       <Route
                           path="*"
                           element={<Navigate to={routeNames.login} replace />}
                       />
                   </>
           }
       </Routes>
    )
}

export default AppRouter
