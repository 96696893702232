import React, { FC } from "react"

import {
    HeartOutlined,
    LogoutOutlined,
    SafetyCertificateOutlined,
    SettingOutlined,
    TeamOutlined, UnorderedListOutlined,
} from "@ant-design/icons"
import { Menu } from "antd"
import { Link } from "react-router-dom"

import styles from "./Nav.module.scss"
import { MenuItem } from "../../../types/nav"
import { adminRole } from "../../constants/roles"
import { routeNames } from "../../constants/routes"
import { useActions } from "../../hooks/useActions"
import { useAllowedRoles } from "../../hooks/useAllowedRoles"

const getItem = (
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    onClick?: (info: string) => void
): MenuItem => {
    return {
        key,
        icon,
        children,
        label,
        onClick
    } as MenuItem
}

const Nav: FC = () => {

    const isAdmin = useAllowedRoles([ adminRole ])

    const { authLogout } = useActions()

    const items: MenuItem[] = [
        getItem(<Link to={routeNames.home}>Объявления</Link>, "0", <UnorderedListOutlined />),
        getItem(<Link to={routeNames.favorites}>Избранное</Link>, "0", <HeartOutlined />),
        isAdmin ? getItem(<Link to={routeNames.staff}>Сотрудники</Link>, "1", <TeamOutlined />) : null,
        // isAdmin ? getItem(<Link to={routeNames.access}>Доступ</Link>, "2", <SafetyCertificateOutlined />) : null,
        isAdmin ? getItem(<span>Параметры</span>, "3", <SettingOutlined />, [
            getItem(<Link to={routeNames.settings_call}>Настройки звонков</Link>, "3-1"),
            getItem(<Link to={routeNames.settings_bl}>Черный список</Link>, "3-2"),
        ]) : null,
        getItem(<a onClick={() => authLogout()}>Выйти</a>, "4", <LogoutOutlined />)
    ]
        .filter((item) => item !== null)

    return (
        <>
            <div className={styles.logoWrapper}>
                <Link to={routeNames.home}>
                    <b>HOMEPRICE</b>
                </Link>
            </div>
            <Menu
                theme="dark"
                mode="inline"
                items={items}
                selectable={false}
            />
        </>
    )
}

export default Nav
