import React, { FC } from "react"

import { Button, Form, Input } from "antd"

import { useActions } from "../../../common/hooks/useActions"
import { useTypedSelector } from "../../../common/hooks/useTypedSelector"
import { showFormErrors } from "../../../common/utils/ui"

const Login: FC = () => {
    document.title = "Вход"

    const [ form ] = Form.useForm()

    const { isLoading } = useTypedSelector((state) => state.auth)
    const { authLogin } = useActions()

    /**
     * Обработчик входа на сервис.
     * @param values - объект с данными для логина
     */
    const onFinish = async (values: { username: string, password: string }) => {
        authLogin(
            values.username,
            values.password,
            () => {
                setTimeout(() => {
                    window.location.href = "/"
                }, 100)
            },
            (errors) => {
                showFormErrors(form, errors)
            }
        )
    }

    return (
        <div className="auth__page">
            <div className="auth__modal">
                <Form
                    name="basic"
                    form={form}
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    autoComplete="off"
                    layout="vertical"
                >
                    <Form.Item>
                        <div className="auth__logo-wrapper">
                            <b>HOMEPRICE</b>&nbsp;CRM
                        </div>
                    </Form.Item>

                    <Form.Item>
                        <div className="auth__top-info">
                            Вход
                        </div>
                    </Form.Item>

                    <Form.Item
                        name="username"
                        rules={[ { required: true, message: "Введите логин." } ]}
                    >
                        <Input placeholder="Логин" size="large" />
                    </Form.Item>

                    <Form.Item
                        name="password"
                        rules={[ { required: true, message: "Введите пароль." } ]}
                    >
                        <Input.Password placeholder="Пароль" size="large" />
                    </Form.Item>

                    <Form.Item>
                        <Button
                            type="primary"
                            size="large"
                            htmlType="submit"
                            className="auth__submit-button"
                            loading={isLoading}
                        >
                            Войти
                        </Button>
                    </Form.Item>

                    {/*<div className="auth__info">*/}
                    {/*    Забыли пароль? <Link to={routeNames.resetPassword}>Сбросить</Link>. <br />*/}
                    {/*</div>*/}
                </Form>
            </div>
        </div>
    )
}

export default Login
